import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "../InputBox/Input";

const Currency = (props) => {
  const {
    selectedCurrency,
    currencyModuleAnchor,
    selectCurrency,
    setCurrencyModuleAnchor,
    filteredCountries,
  } = props;

  const [currencyFilter, setCurrencyFilter] = useState({
    label: "Currency filter",
    input: {
      name: "currencyFilter",
      type: "text",
      value: "",
    },
  });

  const [filterData, setfilterData] = useState(filteredCountries);

  const filterCurrency = (e) => {
    setCurrencyFilter({
      ...currencyFilter,
      input: {
        ...currencyFilter.input,
        value: e.target.value,
      },
    });


    let filterData = filteredCountries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    setfilterData(filterData);
  };

  return (
    <>
      <div className={`ml-3 d-flex align-items-center`}>
        <div className={`mr-2`}>Currency:</div>
        <div
          className={`px-2 filter_module_currency`}
          onClick={(event) => setCurrencyModuleAnchor(event.currentTarget)}
        >
          {selectedCurrency.currency}
        </div>
        <Menu
          style={{ minHeight: "300px", maxHeight: "300px" }}
          id="simple-menu"
          anchorEl={currencyModuleAnchor}
          open={Boolean(currencyModuleAnchor)}
          onClose={() => setCurrencyModuleAnchor(null)}
        >
          <div className="currency-hover-class">
          <MenuItem>
            Currency:
            <Input
              id=""
              className="filterCurrency"
              {...currencyFilter.input}
              onChange={(e) => filterCurrency(e)}
            />
          </MenuItem>

          {filterData.map((item, index) => (
            <MenuItem
              key={index}
              className=""
              onClick={() =>
                selectCurrency(
                  item.currencies[0].symbol,
                  item.currencies[0].code
                )
              }
            >
              <span>
                <img src={item.flag} height={25} width={25} alt="currency" />
              </span>
              <span>^</span>
              <span className="pl-3 currencySymbol">
                {item.currencies[0].symbol}
              </span>
              <span className="pl-3">
                {item.name.length > 15
                  ? `${item.name.substring(0, 15)}....`
                  : item.name}
              </span>
            </MenuItem>
          ))}
          </div>
        </Menu>
      </div>
      <style>
        {`
                    .filter_module_currency {

                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-size: 12px;
                        height: 32px;
                        width : 4rem;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #ffffff;
                        border: 1px solid #cfcfd4;
                    }
                    .filter_module_currency:hover {
                        border-color: #3a3abf;
                        cursor: pointer;
                    }

                    .filter_module_currency:focus {
                        outline: none;
                    }
                `}
      </style>
    </>
  );
};

export default Currency;
