import React from "react";

const SelectCategory = (props) => {
  const { storeData, selectCategoryHandler } = props;
  return (
    <>
      <div className={`ml-3 d-flex align-items-center`}>
        <div className={`mr-2`}>Category:</div>
        <select
          className={`filter_module_group`}
          name="selectedLabel"
          onChange={selectCategoryHandler}
        >
          {storeData.map((item, index) => (
            <option key={`select_service_${index}`} value={item["id"]}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <style>
        {`
                    .filter_module_group {
                            // height: 32px;
                            // border-radius: 4px;
                            // border: 1px solid #cfcfd4;
                            font-size: 12px;
                            height: 32px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #ffffff;
                            border: 1px solid #cfcfd4;                            
                    }
                    .filter_module_group:hover {
                        border-color: #3a3abf;
                        cursor: pointer;
                    }
            
                    .filter_module_group:focus {
                        outline: none;
                    }
                `}
      </style>
    </>
  );
};

export default SelectCategory;
