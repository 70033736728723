import React, { useState } from 'react';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import {
    container_class,
    heading,
    table_Data_class,
    table_heading,
    view_activity,
    sort_data,
    selected_sort,
    display_top_promo_data,
    loading_class
} from './topNPromo.module.scss';

const TopNPromo = (props) => {
    const {
        loading,
        error,
        tableData,
        sortBy,
        toggleAnchor,
        sortByFuntion,
        sortAnchor,
        selectedCurrency
    } = props;
    const history = useHistory();

    return (
        <div className={`px-2 pt-1 pb-2 mr-3 ${container_class}`}>
            <div className={`d-flex mt-2 ml-1 justify-content-between align-items-center`}>
                <span className={` ${heading}`}>Top N Promo</span>
                <span className={` ${view_activity}`} onClick={() => history.push('/dashboard/top-n-promo')}>View Activity</span>
            </div>

            <div className={`d-flex justify-content-end align-items-center my-2 ${sort_data}`}>
                <span className={`mr-2 ${table_heading}`}>Sort By:</span>
                <div className={`px-2 py-1 ${selected_sort}`} onClick={(event) => toggleAnchor(event.currentTarget)}>{sortBy}</div>
                <Menu
                    anchorEl={sortAnchor}
                    open={Boolean(sortAnchor)}
                    onClose={() => toggleAnchor('')}
                >
                    <MenuItem className={`sort_menu_top_table`} onClick={() => sortByFuntion('Discount')}>Discount</MenuItem>
                    <MenuItem className={`sort_menu_top_table`} onClick={() => sortByFuntion('Quantity')}>Quantity</MenuItem>
                    <MenuItem className={`sort_menu_top_table`} onClick={() => sortByFuntion('Revenue')}>Revenue</MenuItem>
                </Menu>
            </div>

            <div className={`${display_top_promo_data}`}>
                {loading
                    ? <div className={`mt-5 ${loading_class}`}><CircularProgress/></div>
                    : error === 'failure'
                        ? <div className={`mt-5 ${loading_class}`}>No data for selected date range</div>
                        : <div>
                            <div
                                className={`d-flex justify-content-between my-3 ${table_heading}`}
                                // style={{ fontSize: '14px', color: '#000000' }}
                            >
                                <span>Promo Name</span>
                                <span>{sortBy}</span>
                            </div>
                            {tableData.map((data, index) => {
                                return index < 6 && (
                                    <div
                                        className={`py-2 d-flex ${table_Data_class} justify-content-between`}
                                        >
                                        <span>{data.promo_name}</span>
                                        <span className={`mr-3`}>
                                            {sortBy === 'Discount'
                                                ? (data.promoDiscount).toFixed(2)
                                                : sortBy === 'Quantity'
                                                    ? data.count
                                                    : `${selectedCurrency.symbol} ${(data.finalTotal).toFixed(2)}`}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>}
            </div>
        </div>
    );
}

export default TopNPromo;
