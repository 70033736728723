import { replaceBlankWithDash } from "../../lib/helper";

export const reviewLogsColumns = [
  {
    Header: 'Index',
    accessor: data => replaceBlankWithDash(data.index),
    id: "index",
    minWidth: 60
  },
  {
    Header: "Product Name",
    accessor: data => replaceBlankWithDash(data.productName),
    id: "productName"
  },
  {

    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.dateTime),
    id: "dateTime",
    minWidth: 110
  },
  {
    Header: "Category Name",
    accessor: data => replaceBlankWithDash(data.categoryName),
    id: "categoryName"
  },
  // {
  //   Header: "Sub Category Name",
  //   accessor: data => replaceBlankWithDash(data.subCategoryName),
  //   id: "subCategoryName"
  // },
  // {
  //   Header: "Sub Sub Category Name",
  //   accessor: data => replaceBlankWithDash(data.subSubCategoryName),
  //   id: "subSubCategoryName"
  // },
  {
    Header: "Full Name",
    accessor: date => replaceBlankWithDash(date.fullName),
    id: "fullName",
  },

  {
    Header: "Review Title",
    accessor: date => replaceBlankWithDash(date.reviewTitle),
    id: "reviewTitle",
  },
  {
    Header: "Review Description",
    accessor: date => replaceBlankWithDash(date.reviewDescription),
    id: "reviewDescription",
  },

  {
    Header: "Platform Name",
    accessor: data => replaceBlankWithDash(data.platform),
    id: "platform"
  },
  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.lat),
    id: "lat"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.long),
    id: "long"
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.cityName),
    id: "cityName"
  },
  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.countryName),
    id: "countryName"
  },

];
