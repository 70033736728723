import React from "react";

function Button(props) {
  return (
    <div className={true && props.classes}>
      <button className={`btn btn-primary`} {...props}>
        {props.children}
      </button>
    </div>
  );
}

export default Button;
