import { createBrowserHistory } from 'history';
import axios from "axios";
import { getCookie } from "./session";
import { logout } from "./helper";
import ls from "local-storage";
import jstz from "jstz";
import { api } from "../../src/config/app";
import {getCurrencySymbol} from './helper';
import {getNewAccessToken} from './refreshToken'
const history = createBrowserHistory();
let url;
// global API HOST ( API Domain URL)

// const Token =
//   "eyJ6aXAiOiJERUYiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiUlNBLU9BRVAiLCJraWQiOiJqVkIzcDkxYW5FS25PSEZXT0hwNmhubkppTW5MblVBQkZwYk9fb1ZzQl9rIn0.ANY0BugRC01UP3pFlrldBVp2MhBcTB3ZnvUptjRugw-c9MrYsVw-CsYaDFbPWrmyVNy4nrtdlydW7253ZSWHIVl469_30aNLzYPrZniByeOBs8kjdp-kxVshOZ7KBtSVp61-3fptr53k_juf5rzjtjeNE1nL14X-we7I_Ok6TQU.pE1RRST3tFQhTJi-Kl1YBg.4isrkPCvcI_NsbdEkUfw7RCsA0hlJD-cgw5e_15VttQ6xG6v24fV_pVSZqIv-7w1uvqk0_aVk3AOHonqO0c34gnTzYPu-dRh66U5lQkyZ5AJT_JB5GiD3MYFR9Veb63NDvjDQkj-yMQyB413HmNsjRZSlSqHN2YIrSliAxcy5Dn7_w1whYL-uRb4lkeNvp76kWGrtymjWVUTKyc18UjVxEAP7sRukI_hp9jlmyPYME7jplvMOLRxe4p8nOXqAcBSqkYPZja0xMDUEEfePRc9C5bHFKmUCIfvl6A_Y5o_NAnDl7Na5s8V3sT0mOMfByvFXigiHOEdCi93RQM3yVJ83nowlX0woPRT5EzPNA7HKrLWa5pQ3V4WOaGxqKN_CGtdxBozS4AbOAQmfpmSsjUWMcaDKeKHSsU4EDRN_TssuLXvsUzCECflyk10OysOyeIf_fFzb2nqA234jrwWzpNdD7KypwNPrl0_RJ8b3eq6H2DTIceoPE0-2Zo9YEiYoRQwsf2l2SAw6OXHf9dl4v5FIq1gkXOBuwdr3M8CBSjpe5E.RGWFI3dmaSJHJyEjt5cP7w";

// Merging a full URL - for e.g. ->   "https://wwww.apiurl.com"  + "/users"
const getUrl = endpoint => api + endpoint;

var timezone = jstz.determine();
let yourTimeZone = timezone.name();

// Making a POST request (without token), use this function for all POST requests in project
export const post = async (endpoint, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(getUrl(endpoint), data.body, {
      headers: data.headers 
    })
    .then((data) => {
      return resolve(data.data);
    })
    .catch((err) => {
      if(err.response && err.response.status === 401) {
        alert('Your session has been expired!')
        window.location.reload()
      }
    })
  }) 
};

// Making a PATCH request (without token), use this function for all PATCH requests in project
export const patch = async (endpoint, data) => {
  return axios.patch(getUrl(endpoint), data, {
    headers: { "Content-Type": "application/json", language: "en" }
  });
};

const getAccessToken = (getData) => {
  const accessToken = ls.get('token')
  const refreshToken = ls.get('refreshToken');
  return getNewAccessToken(refreshToken, accessToken)
    .then((data) => {
      ls.remove('token');
      ls.set('token', data.data.accessToken);
      // return getForReport.call(this, url)
      window.location.reload()
    })
    .catch((err) => {
      console.log('Your accessToken', err)
    })
 
  
}

///// Making a GET request, use this function for all GET requests in project
export const get = async (endpoint, header) => {
  url = endpoint;
  endpoint = getUrl(endpoint);
  
  return new Promise((resolve, reject) => {
    axios
       .get(endpoint, {
          headers: {
             Authorization: `${ls.get("token")}`,
             "Content-Type": "application/json",
             language: "en"
          },
          params: {
            store_id: ls.get('store_id'),
            timezone: yourTimeZone
          },  

       })
       .then((res) => {
          if(res.status && res.status === 204) {
             return reject({data: {message: "Data Not Found"}})
          }
          return resolve(res);
       })
       .catch((err) => {
          if(err.response && err.response.status === 406) {
            getAccessToken(get)
          }
          if (err.response && err.response.status === 401) {
             logout(history);
          }
          return reject(err);
       });
 });
};




export const getForReport = async (endpoint) => {
  
  return new Promise((resolve, reject) => {
    url = endpoint;
    endpoint = getUrl(endpoint);
    const currency = ls.get('currency') || "INR"
    const currency_symbol = getCurrencySymbol(currency)
    axios
       .get(endpoint, {
          headers: {
             Authorization: `${ls.get("token")}`,
             "Content-Type": "application/json",
             language: "en"
          },
          params: {
            store_id: ls.get('store_id'),
            timezone: yourTimeZone,
            currency_symbol
          },
       })
       .then((res) => {
          if(res.status && res.status === 204) {
             return reject({data: {message: "Data Not Found", status: 204}})
          }
          return resolve(res);
       })
       .catch((err) => {
          if(err.response && err.response.status === 406) {
            getAccessToken(getForReport)
          }
          if (err.response && err.response.status === 401 || err.response && err.response.status === 400 && err.response.data.message === "Token Not found.") {
             logout(history);
          }
          return reject(err);
       });
 });
};




// Making a POST request (with token), use this function for all POST requests in project
export const postWithToken = async (endpoint, data) => {
  console.log(String(await getCookie("token", "")).replace(/%20/g, " "));
  axios.defaults.headers.common["Authorization"] = String(
    await getCookie("token", "")
  ).replace(/%20/g, " ");

  return axios.post(getUrl(endpoint), data, {
    headers: { "Content-Type": "application/json", language: "en" }
  });
};

// Making a PATCH request (with token), use this function for all PATCH requests in project
export const patchWithToken = async (endpoint, data) => {
  axios.defaults.headers.common["Authorization"] = await getCookie("token", "");
  return axios.patch(getUrl(endpoint), data, {
    headers: { "Content-Type": "application/json", language: "en" }
  });
};

// Making a PUT request (with token), use this function for all PUT requests in project
export const putWithToken = async (endpoint, data) => {
  axios.defaults.headers.common["Authorization"] = await getCookie("token", "");
  return axios.put(getUrl(endpoint), data, {
    headers: { "Content-Type": "application/json", language: "en" }
  });
};

// Making a DELETE request (with token), use this function for all DELETE requests in project
export const deleteReq = async endpoint => {
  axios.defaults.headers.common["Authorization"] = await getCookie("token", "");
  return axios.delete(getUrl(endpoint), {
    headers: { "Content-Type": "application/json", language: "en" }
  });
};
