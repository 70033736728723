//library import
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import DateRangePicker from "../dateRangePicker/dateRangePicker";
import "./dialog.scss";

const styles = {
  root: {
    maxWidth: "fit-content"
  }
};
const SimpleDialog = props => {
  let dialogContent = null;

  switch (props.type) {
    case "dateRangePicker":
      dialogContent = (
        <DateRangePicker
          open={props.open}
          handlerRange={props.handlerRange}
        ></DateRangePicker>
      );
      break;

    default:
      break;
  }
  let { classes } = props;
  return (
    <div className="dialog">
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="simple-dialog-title"
        open={props.open}
        maxWidth="md"
      >
        {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}
        {dialogContent}
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(SimpleDialog);
