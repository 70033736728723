//library import
import React from "react";
import Select from "react-select";

//custom import
import "./filter.scss";
import "../../../node_modules/react-dropdown/dist/";
class Filter extends React.Component {
  state = {
    filterd: {
      name: {
        placeholder: "Select an option",
        selectedOption: null,
        options: [
          { value: "one", label: "One" },
          { value: "one", label: "One" },
          { value: "one", label: "One" },
          { value: "one", label: "One" },
          { value: "one", label: "One" },
        ],
      },
    },
  };

  handleChange = (selectedOption) => {
    // this.setState({ selectedOption });
  };

  render() {
    return (
      <div className="filter-root">
        <div className="filter-div">
          <div className="d-flex time-range-div flex-wrap">
            {Object.keys(this.state.filterd).map((index) => {
              return (
                <Select
                  value={this.state.filterd[index].selectedOption}
                  options={this.state.filterd[index].options}
                  placeholder={this.state.filterd[index].placeholder}
                ></Select>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
