import React, { Component } from "react";
import ReactHighcharts from 'react-highcharts'

import "./reactHighCharts.scss";
class ReactHighCharts extends Component {

  render() {

    return (
      <div>
        <ReactHighcharts config={this.props.config} />
      </div>
    )


  }
}
export default ReactHighCharts;
