//library import
import React, { useState } from "react";
import ReactTable from "react-table";
import DescriptionIcon from "@material-ui/icons/Description";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Fab from "@material-ui/core/Fab";
import moment from "moment";
import { DatePicker } from "antd";
//custom import
import Select from "../selectBox/selectBox";
import Dialog from "../dialog/dialog";
import Pagination from "../pagination/pagination";
import { dateFilter } from "../../fixtures/dateFilter/dateFIlter";
import "antd/dist/antd.css";
import Filter from "../../components/filter/filter";
//css import
import "./reactTable.scss";
import "./react-table.css";
import { $CombinedState } from "redux";

const { RangePicker } = DatePicker;
class reactTable extends React.Component {
  intDate = {
    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };

  state = {
    filtered: [],
  };

  handlerDialog = (flag) => {
    this.setState({ open: flag });
  };

  handleRange = (...arges) => {
    let startDate =
      arges[0].length != 0
        ? arges[0][0].startOf("day").format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.startDate;
    let endDate =
      arges[0].length != 0
        ? arges[0][1].endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.endDate;
    if (this.props.handleRange) {
      this.props.handleRange(startDate, endDate);
    }
  };

  render() {
    return (
      <div>
        {/* <Dialog
          type={"dateRangePicker"}
          open={this.state.open}
          range={this.state.range}
          handleClose={this.handlerDialog.bind(this, false)}
          handlerRange={this.handleRange.bind(this)}
        ></Dialog> */}
        {/* <Filter></Filter> */}
        <div className="react-table">
          <div className="table-lable-div">
            <div className="table-icon">
              <DescriptionIcon></DescriptionIcon>
            </div>
            <div className="table-lable">{this.props.lable || ""}</div>
            <div className="table-filters ">
              <div className="filter-div">
                <div
                  className="d-flex time-range-div"
                  onClick={this.handlerDialog.bind(this, true)}
                >
                  <div className="filter-lable">
                    <EventNoteIcon></EventNoteIcon>
                  </div>
                  <div className="date-filter-lable">
                    <RangePicker
                      ranges={{
                        ...dateFilter,
                      }}
                      defaultValue={[
                        moment(moment().startOf("day")),
                        moment(moment().endOf("day")),
                      ]}
                      onChange={this.handleRange}
                      disabledDate={(current) => {
                        return current && current > moment().add(0, "days");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            resizable={false}
            data={this.props.data}
            pageSize={this.props.data && this.props.data.length}
            showPagination={false}
            // showPaginationBottom={this.props.data.length != 0 ? true : false}
            className="-striped -highlight"
            columns={this.props.columns}
            minRows={9}
            noDataText={this.props.noDataText || "No rows found"}
            // onPageChange={
            //   this.props.onPageChange ? this.props.onPageChange : () => { }
            // }
            // PaginationComponent={Pagination}
          />
        </div>
      </div>
    );
  }
}
export default reactTable;
