import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  container_class,
  heading,
  table_heading,
  table_Data_class,
  loading_class,
  view_activity,
} from "./promoAnalysisTable.module.scss";

const TableHeading = ["coupon", "Gross revenue", "No. of orders"];

const keyToRender = ["promo_code", "sum_finalTotal", "sum_count"];

const PromoAnalysisTable = (props) => {
  const { tableData, loading, error, selectedCurrency } = props;
  const history = useHistory();

  const selectedKeys = (selectedKey) => {
    let returnKey = false;

    keyToRender.forEach((key) => {
      if (key === selectedKey) {
        returnKey = true;
      }
    });
    return returnKey;
  };

  return (
    <div className={`px-2 pt-1 pb-2 mr-3 ${container_class}`}>
      <div className={`d-flex mt-2 ml-1 justify-content-between align-items-center`}>
        <span className={` ${heading}`}>Promo Usage Analysis</span>
        <span
          className={` ${view_activity}`}
          onClick={() => history.push("/dashboard/promo-usage-analysis")}
        >
          View Activity
        </span>
      </div>

      <div className={`mt-2 d-flex ${table_heading}`}>
        {TableHeading.map((heading, index) => (
          <div
            className={`py-2 px-2 d-flex ${
              index === 0 ? "" : "justify-content-center"
            }`}
            key={`column_heading_${index}`}
            style={index === 0 ? { width: "200px" } : { width: "150px" }}
          >
            {tableData.length !== 0 ? heading : ""}
          </div>
        ))}
      </div>

      <div className={``}>
        {loading ? (
          <div
            className={`d-flex justify-content-center py-5`}
            id={`${loading_class}`}
          >
            <CircularProgress/>
          </div>
        ) : error === "success" &&
          Array.isArray(tableData) &&
          tableData.length !== 0 ? (
          tableData.map((data, index) => {
            return (
              index <= 7 && (
                <div
                  className={`d-flex ${table_Data_class}`}
                  style={{ borderTop: "none" }}
                  key={`column_data_${index}`}
                >
                  {Object.keys(data).map((column, innerIndex) => {
                    return (
                      selectedKeys(column) && (
                        <div
                          className={`py-2 px-2 d-flex ${
                            column === "promo_code"
                              ? ""
                              : "justify-content-center"
                          }`}
                          key={`column_data_${index}${innerIndex}`}
                          style={
                            column === "promo_code"
                              ? { width: "200px" }
                              : { width: "150px" }
                          }
                        >
                          {`${
                            column === "sum_finalTotal"
                              ? `${selectedCurrency.symbol} ${Number(
                                  data[column]
                                ).toFixed(2)}`
                              : data[column]
                          }`}
                        </div>
                      )
                    );
                  })}
                </div>
              )
            );
          })
        ) : (
          <div
            className={`d-flex justify-content-center py-5`}
            id={`${loading_class}`}
          >
            No data for selected date range
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoAnalysisTable;
