import React, { Component } from 'react';
import ReactToPrint from "react-to-print";

class PrinAndExport extends Component {
    state = {};

    render() {
        const { componentRef } = this.props;
        return (
            <>
                <div className="d-flex print_export mb-2">
                    <div className="hover_class_print_export">
                        <ReactToPrint
                            trigger={() => (
                                <span>
                                    <i className="fas fa-print mr-2"></i>
                                    <span>Print</span>
                                </span>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                    <span
                        className={`ml-3 hover_class_print_export`} 
                        onClick={() => this.props.exportData()}
                    >
                        <i className="fas fa-download mr-2"></i>
                        <span>Export</span>
                    </span>
                </div>
                <style>
                    {`
                        .print_export {
                            font-size: 14px;
                        }
                        .hover_class_print_export:hover {
                            cursor: pointer;
                        }
                    `}
                </style>
            </>
        );
    }
}

export default PrinAndExport;