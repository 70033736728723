import React, { Component } from "react";
import ApexCharts from "react-apexcharts";
import { container_class, loading_class } from "./couponAnalysis.module.scss";
import { CircularProgress } from "@material-ui/core";

class CouponAnalysisGraph extends Component {
  render() {
    const { error, loading, response } = this.props;
    return (
      <div
        className={`mx-3 my-4 px-3 py-2 ${container_class}`}
      >
        {loading ? (
          <div
            className={` ${loading_class}`}
          >
            <CircularProgress/>
          </div>
        ) : error === "failure" ? (
          <div
            className={` ${loading_class}`}
          >
            No data for selected date range
          </div>
        ) : (
          <ApexCharts
            options={response && response.options && response.options}
            series={response && response.series && response.series}
            type="bar"
            height={350}
          />
        )}
      </div>
    );
  }
}

export default CouponAnalysisGraph;
