import { get } from "../lib/request";
import moment from "moment";

const unixTimeStamp = (timestamp) => {
  return moment(timestamp).unix();
};

export const getTopNPromoCodeApi = (
  startDate,
  endDate,
  selectedCurrency,
  selectedService,
  sortBy,
  storeId
) => {
  let url = `/promo/top?start_timestamp=${unixTimeStamp(
    startDate
  )}&end_timestamp=${unixTimeStamp(endDate)}&currency=${
    selectedCurrency.currency
  }&service_type=${selectedService}&sort_by=${sortBy}&store_categories_id=${storeId}`;
  return get(url);
};
