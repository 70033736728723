//library import
import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import PollIcon from "@material-ui/icons/Poll";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ls from "local-storage";

//custom import
import {
  container_class,
  render_dashboard_menu,
} from "./sidePanel.module.scss";

const useStyles = makeStyles({
  drawerPaper: {
    width: "100% !important",
  },
});

const SidePanel = (props) => {
  const { data, url } = props;
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <div className={`d-flex justify-content-end ${container_class}`}>
        <div className={`px-3 py-2`}>
          <HomeIcon
            className={`hover-pointer-class`}
            onClick={() => {
              // ls.remove("endDate");
              // ls.remove("startDate");
              return history.push(url)}}
          />
          <PollIcon
            className={`ml-2 hover-pointer-class`}
            onClick={() => setDrawerOpen(true)}
          />
        </div>
        <Drawer
          open={drawerOpen}
          anchor={"top"}
          onClose={() => setDrawerOpen(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={`d-flex pl-3 pb-2`}>
            {data &&
              data.map((item, index) => {
                return (
                  index !== 2 && (
                    <NavLink
                      activeClassName={`dashboard-menu-active`}
                      className={`d-flex align-items-center mt-2 mr-2 py-1 pl-1 pr-2 ${render_dashboard_menu} set_width_class`}
                      key={index}
                      to={item.url}
                      onClick={() => setDrawerOpen(false)}
                    >
                      <ListItemIcon>
                        {item.icon && (
                          <ListItemIcon>{<item.icon></item.icon>}</ListItemIcon>
                        )}
                      </ListItemIcon>
                      <span>{item.name}</span>
                    </NavLink>
                  )
                );
              })}
          </div>
        </Drawer>
      </div>
      <style>
        {`
				.dashboard-menu-active {
					background-color: #3879ba;
					color: #ffffff;
				}

				.dashboard-menu-active:hover {
					color: #ffffff !important;
				}

				.dashboard-menu-active > div > div {
					color: #ffffff;
				}
			`}
      </style>
    </>
  );
};

export default SidePanel;
