import { get } from "../lib/request";
import moment from "moment";

const unixTimeStamp = (timestamp) => {
  return moment(timestamp).unix();
};


export const getPromoCodeAnalysisAPi = (
  // dateSelect,
  startDate,
  endDate,
  selectedCurrency,
  selectedService,
  selectedStatus,
  storeId
) => {
  let url = `/dashboard/promo/usage?start_timestamp=${unixTimeStamp(
    startDate
  )}&end_timestamp=${unixTimeStamp(endDate)}&currency=${
    selectedCurrency.currency
  }&service_type=${selectedService}&store_categories_id=${storeId}`;
  return get(url);
};

export const getTopNPromoApi = (
  startDate,
  endDate,
  selectedCurrency,
  selectedService,
  selectedStatus,
  sortBy,
  storeId
) => {
  let url = `/dashboard/promo/top?start_timestamp=${unixTimeStamp(
    startDate
  )}&end_timestamp=${unixTimeStamp(endDate)}&currency=${
    selectedCurrency.currency
  }&sort_by=${sortBy}&service_type=${selectedService}&store_categories_id=${storeId}`;
  return get(url);
};
