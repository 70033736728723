import React from "react";
import { CircularProgress } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";

import {
  inner_container_class,
  table_heading,
  table_Data_class,
  loading_class,
  main_heading,
  table_icon,
  link_class,
} from "./promoCodeAnalysis.module.scss";

const TableHeading = [
  "Promo Name",
  "coupon",
  "Fixed Value",
  "Status",
  "Net revenue",
  "Gross discounts from coupons",
  "Gross revenue",
  "No. of orders",
  "Avg. net order value",
  "Avg. order discount",
  "Distinct buyers",
  "discount",
];

const keyToRender = [
  "promo_code",
  "promo_name",
  "applied_on",
  "activation_status",
  "sum_finalUnitPrice",
  "sum_promoDiscount",
  "sum_finalTotal",
  "sum_count",
  "avg_finalUnitPrice",
  "avg_promoDiscount",
  "unique_count",
  "percent_discount",
];

const PromoCodeTable = (props) => {
  const {
    tableData,
    loading,
    error,
    selectedCurrency,
    componentRef,
    getPromoDetailedData,
  } = props;

  const selectedKeys = (selectedKey) => {
    let returnKey = false;
    keyToRender.forEach((key) => {
      if (key === selectedKey) {
        returnKey = true;
      }
    });
    return returnKey;
  };

  const toFixValue = (value, key) => {
    const { symbol } = selectedCurrency;
    let fixedValue = null;
    if (
      key === "sum_finalUnitPrice" ||
      key === "sum_finalTotal" ||
      key === "avg_finalUnitPrice" ||
      key === "avg_promoDiscount" ||
      key === "sum_promoDiscount" ||
      key === "percent_discount"
    ) {
      fixedValue = `${key === "percent_discount" ? "" : symbol} ${Number(
        value
      ).toFixed(2)} ${key === "percent_discount" ? "%" : ""}`;
    } else {
      fixedValue = value;
    }

    return fixedValue;
  };

  return (
    <div
      className={`p-2 ${inner_container_class}`}
      ref={componentRef}
    >
        <div className={`${table_icon}`}>
          <DescriptionIcon></DescriptionIcon>
        </div>
        <div className={`${main_heading}`}>Analyzing coupon usage</div>

      <div className={`mt-2 d-flex ${table_heading}`}>
        {TableHeading.map((heading, index) => (
          <div
            className={`py-2 px-2 d-flex ${
              index === 0 || index === 1 ? "" : "justify-content-center"
            }`}
            key={`column_heading_${index}`}
            style={
              index === 0 || index === 1
                ? { width: "200px", textAlign: "center" }
                : { width: "150px", textAlign: "center" }
            }
          >
            {heading}
          </div>
        ))}
      </div>
      <div className={``}>
        {loading ? (
          <div
            className={`d-flex justify-content-center align-items-center py-5 ${table_Data_class}`}
            id={`${loading_class}`}
          >
            <CircularProgress/>
          </div>
        ) : error === "success" ? (
          tableData.map((data, index) => {
            return (
              <div
                className={`d-flex ${table_Data_class}`}
                style={
                  index % 2 === 0
                  ? { background: "#F6F6F6", border: "1px solid #dee2e6" }
                  : { background: "#FFF", border: "1px solid #dee2e6" }
                 }


                key={`column_data_${index}`}
              >
                {Object.keys(data).map((column, innerIndex) => {
                  return (
                    selectedKeys(column) && (
                      <div
                        onClick={() => getPromoDetailedData(data)}
                        className={`py-2 px-2 d-flex
                                                            ${
                                                              column ===
                                                                "promo_name" ||
                                                              column ===
                                                                "promo_code"
                                                                ? ""
                                                                : "justify-content-center"
                                                            }
                                                            ${
                                                              column ===
                                                              "promo_name"
                                                                ? `${link_class}`
                                                                : ""
                                                            }`}
                        key={`column_data_${index}${innerIndex}`}
                        style={
                          column === "promo_code" || column === "promo_name"
                            ? { width: "200px" }
                            : { width: "150px" }
                        }
                      >
                        {toFixValue(data[column], column)}
                      </div>
                    )
                  );
                })}
              </div>
            );
          })
        ) : (
          <div
            className={`d-flex justify-content-center align-items-center py-5`}
            id={`${loading_class}`}
          >
            No data for selected date range
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoCodeTable;
