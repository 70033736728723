import { get } from "../lib/request";
import moment from "moment";

const unixTimeStamp = (timestamp) => {
    return moment(timestamp).unix()
};

export const getPromoCodeUsageAnalysisApi = (start_date, end_date, selectedCurrency, selectedService, selectedStatus, exportData, storeId) => {
    let url = `/promo/usage?start_timestamp=${unixTimeStamp(start_date)}&end_timestamp=${unixTimeStamp(end_date)}&currency=${selectedCurrency.currency}&service_type=${selectedService}&store_categories_id=${storeId}&status=${selectedStatus}`;
    url = exportData === 'export' ? `${url}&export=1` : url;
    return get(url);
};

export const getPromoAnalysisDetailedData = (serviceType, storeId, id) => {
    let url = `/dashboard/log?&service_type=${serviceType}&store_categories_id=${storeId}&_id=${id}`;
    return get(url);
};
