import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from "@material-ui/icons/Description";
import { CircularProgress } from "@material-ui/core";

// scss import
import {
  table_icon,
  text_data_color,
  container_class_topntable,
  topntable_heading,
  display_top_promo_data,
  loading_class,
  show_range,
  top_display_box,
  material_icon,
  align_material_icon,
  help_icon,
  help_icon_size,
  display_top_data,
  filter_module,
  show_toggle,
  filter_dropdown,
  sort_arrow,
} from "./topNPromo.module.scss";

const TopNPromoTable = (props) => {
  const { loading, error, tableData, sortByFuntion } = props;

  const showRange = (localDiscount) => {
    return (
      Number(localDiscount.toFixed(2) * 100) /
      Number(tableData.promoDiscountTotal.toFixed(2))
    );
  };
  return (
    <>
      <div className={`ml-2 mt-2 d-flex`}>
        {[1, 2].map((data, index) => (
          <div className={`d-flex ${top_display_box} mr-3`}>
            <div className={` ${align_material_icon}`}>
              <EmailIcon className={`${material_icon}`} />
            </div>
            <div className={` ${display_top_data}`}>
              {loading ? (
                <CircularProgress/>
              ) : error === "failure" ? (
                "No Data"
              ) : index === 0 ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    {tableData.totalCount}
                  </div>
                  <div>Total Count</div>
                </div>
              ) : (
                <div>
                  <div style={{ textAlign: "center" }}>
                    {tableData.totalUniquePromo}
                  </div>
                  <div>Unique Promo</div>
                </div>
              )}
            </div>
            <div className={` ${help_icon}`}>
              <HelpIcon className={` ${help_icon_size}`} />
            </div>
          </div>
        ))}
      </div>
      <div className={`mt-4 w-100% ml-2 p-1 mr-2 ${container_class_topntable}`}>
        <div className={`d-flex justify-content-between align-items-center`}>
        <div className={`${table_icon}`}>
          <DescriptionIcon></DescriptionIcon>
        </div>
          <div className={` mt-1 ${topntable_heading}`}>Top Promo</div>
          <div className={`d-flex ${filter_module}`}>
            <div className={`mr-2 ${sort_arrow}`}>
              <i className={`fas fa-arrow-up`}></i>
            </div>
            <select
              className={`mr-2 ${filter_dropdown}`}
              onChange={(event) => sortByFuntion(event)}
            >
              <option value="1">Quantity</option>
              <option value="2">Discount</option>
              <option value="3">Revenue</option>
            </select>
            <div className={`px-2 ${show_toggle}`}>show all</div>
          </div>
        </div>

        <div className={`mt-3 ${display_top_promo_data}`}>
          {loading ? (
            <div
              className={`d-flex align-items-center justify-content-center ${loading_class}`}
            >
              <CircularProgress/>
            </div>
          ) : error === "failure" ? (
            <div
              className={`d-flex align-items-center justify-content-center ${loading_class}`}
            >
              No data for selected date range
            </div>
          ) : (
            tableData.table &&
            tableData.table.map((data, index) => {
              return (
                <div className={`py-2 mb-1`}>
                  <div className={`mb-1`}>
                    <span style={{ color: "#3532ec" }}>{data.promo_name}</span>
                    <span
                      className={`ml-2 ${text_data_color}`}
                    >{`${data.promo_code} ${data.count}`}</span>
                  </div>
                  <div className={`${show_range}`}>
                    <div
                      style={{
                        height: "100%",
                        width: `${showRange(data.promoDiscount)}%`,
                        backgroundColor: "#3532ec",
                      }}
                    ></div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default TopNPromoTable;
