export const LanguageOptions = [
  {
    name: "English",
    abbr: "en"
  },
  {
    name: "Portugese",
    abbr: "pt"
  },
  {
    name: "Spanish",
    abbr: "es"
  }
];
