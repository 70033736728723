import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import { CircularProgress } from "@material-ui/core";
import { getPromoAnalysisDetailedData } from "../../../services/promoUsageAnalysis";

import {
  inner_container_class,
  table_heading,
  main_heading,
  log_table_icon,
  table_Data_class,
  loading_class,
  reportModal_Btn,


  container_class,
  containerDiv,
} from "./promoCodeAnalysis.module.scss";

const TableHeading = [
  "CUSTOMER NAME",
  "DATE AND TIME",
  "BOOKING ID",
  "APPLIED ON",
  "BOOKING TOTAL",
  "DELIVERY FEE",
  "PROMO DISCOUNT",
  "TAXES",
  "OTHER DISCOUNT",
  "FINAL CART",
  "STATUS",
];

function PromoCodeUsageDetailedAnalysis(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorFetching, setErrorFetching] = useState("failure");
  const [responseData, setResponseData] = useState([]);
  useEffect(() => {
    getPromoDetailedData();
  }, []);

  // detailed api response for each promo
  async function getPromoDetailedData(data) {
    const { serviceType, storeId, id } = props.match.params;
    try {
      let formattedObject = {};
      let tempArray = [];
      let response = await getPromoAnalysisDetailedData(
        serviceType,
        storeId,
        id
      );
      setLoading(false);
      setErrorFetching("success");
      response.data.data.map((data, index) => {
        formattedObject = {
          user_name: data["user_name"],
          time_stamp: data["time_stamp"],
          order_id: data["order_id"],
          applied_on:
            data["applied_on"].applied_on_status_code === 1 ? "Cart" : "N/A",
          taxableAmount: data["accounting"].taxableAmount,
          deliveryFee: data["accounting"].deliveryFee,
          promoDiscount: data["accounting"].promoDiscount,
          taxAmount: data["accounting"].taxAmount,
          offerDiscount: data["accounting"].offerDiscount,
          finalTotal: data["accounting"].finalTotal,
          transaction_message: data["transaction_status"].transaction_message,
        };
        tempArray.push(formattedObject);
      });
      setResponseData(tempArray);
    } catch (err) {
      console.log("getPromoCodeApi", err);
    }
  }

  const dataFormatting = (data, column) => {
    if (
      column === "taxableAmount" ||
      column === "deliveryFee" ||
      column === "promoDiscount" ||
      column === "taxAmount" ||
      column === "offerDiscount" ||
      column === "finalTotal"
    ) {
      return Number(data).toFixed(2);
    } else if (column === "time_stamp") {
      return moment.unix(data).format("YYYY-MM-DD HH:mm A");
    } else {
      return data;
    }
  };

return (
  <React.Fragment>
  <div className={`${containerDiv}`}>
    
      <div
        className={`px-3 pb-3 pt-1 border-bottom bg-white ${container_class}`}
      >
        {/* link to dashboard */}
        <div
          className={`globalFontSize`}
          style={{cursor:'pointer'}}
          onClick={() => history.push("/dashboard/overview")}>
            <i className="fas fa-angle-left mr-2"></i>Reports
        </div>
        <h4 className="title">Promo logs</h4>
      </div>

      <div
        className={`p-2 ${inner_container_class}`}
        style={{ marginTop: "7rem" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className={`${log_table_icon}`}>
            <DescriptionIcon></DescriptionIcon>
          </div>
          <div className={`${main_heading}`}>Promo Logs Table</div>
          <button 
            className={`mr-4 ${reportModal_Btn}`} 
            style={{background: "#dee2e6"}}
            onClick={() => history.push("/dashboard/promo-usage-analysis")}>Back
          </button>
        </div>
        
        <div className={`mt-2 d-flex ${table_heading}`}>
          {TableHeading.map((heading, index) => {
            return (
              <div
                className={`py-2 px-2 d-flex ${
                  index === 0 || index === 2 ? "" : "justify-content-center"
                }`}
                key={`column_heading_${index}`}
                style={
                  index === 0 || index === 2
                    ? { width: "200px", textAlign: "center" }
                    : index === 1
                    ? { width: "140px", textAlign: "center" }
                    : { width: "115px", textAlign: "center" }
                }
              >
                {heading}
              </div>
            );
          })}
        </div>
        <div className={``}>
          {loading ? (
            <div
              className={`d-flex justify-content-center py-5 ${table_Data_class}`}
              id={`${loading_class}`}
            >
            <CircularProgress/>
            </div>
          ) : errorFetching === "success" ? (
            responseData.map((data, index) => {
              return (
                <div
                  className={`d-flex ${table_Data_class}`}
                  style={
                    index % 2 === 0
                    ? { background: "#F6F6F6", border: "1px solid #dee2e6" }
                    : { background: "#FFF", border: "1px solid #dee2e6" }
                   }
                  key={`column_data_${index}`}
                >
                  {Object.keys(data).map((column, innerIndex) => {
                    return (
                      <div
                        className={`py-2 px-2 d-flex ${
                          innerIndex === 0 || innerIndex === 2
                            ? ""
                            : "justify-content-center"
                        }`}
                        key={`column_data_${index}${innerIndex}`}
                        style={
                          innerIndex === 0 || innerIndex === 2
                            ? { width: "200px", textAlign: "center" }
                            : innerIndex === 1
                            ? { width: "140px", textAlign: "center" }
                            : { width: "115px", textAlign: "center" }
                        }
                      >
                        {data[column]
                          ? dataFormatting(data[column], column)
                          : "N/A"}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div
              className={`d-flex justify-content-center py-5 ${table_Data_class}`}
              id={`${loading_class}`}
            >
              Ops!!...Something Went Wrong
            </div>
          )}
        </div>
      </div>
   
  </div>
</React.Fragment>
)
}

export default PromoCodeUsageDetailedAnalysis;